import { areIntervalsOverlapping } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { createSelector } from 'reselect';

import { bookingDetailsSelector } from '@/redux/modules/booking/selectors';
import { TRootState } from '@/redux/rootReducer';
import { getDeliveryFromQuery, getFromAndTo } from '@/redux/selectors/queryParams';
import { TDateRangeString } from '@/services/types/date/date.types';

import { getBookingOrQuoteDeliverableCampground } from '../bill/data';
import { getBookingOrQuoteDates } from '../bill/dates';
import { getListingData } from './page';

type TAvailabilityData = TRootState['availability']['data'];

const selectAvailability = (state: TRootState) => {
  return state.availability.data;
};

export const getUnavailableDates = createSelector<
  TRootState,
  TAvailabilityData,
  ReturnType<typeof getBookingOrQuoteDates>,
  ReturnType<typeof getDeliveryFromQuery>,
  ReturnType<typeof getBookingOrQuoteDeliverableCampground>,
  ReturnType<typeof getListingData>,
  TDateRangeString,
  { from: Date; to: Date }[]
>(
  selectAvailability,
  getBookingOrQuoteDates,
  getDeliveryFromQuery,
  getBookingOrQuoteDeliverableCampground,
  getListingData,
  bookingDetailsSelector,
  (
    dates,
    checkoutBookingDates,
    deliveryFromQuery,
    bookingOrQuoteDeliverableCampground,
    listingData,
    bookingDates,
  ) => {
    const mappedFn = ({ from, to }: { from: string | Dayjs; to: string | Dayjs }) => {
      // The dates are already extended/reduced from the server based on the turnaround
      let fromDate = dayjs(from);
      const endDate = dayjs(to);

      if (fromDate.isAfter(endDate)) {
        fromDate = dayjs(from);
      }

      return {
        from: fromDate.toDate(),
        to: endDate.toDate(),
      };
    };

    const datesClone: { from: string | Dayjs; to: string | Dayjs }[] = [...dates];

    // If there is a deliverable campground, do not let user chose dates before instant book leeway.
    // Sibling bookings must be instant book for now.  API prevents this, but shows gross error message.
    if (bookingOrQuoteDeliverableCampground || Boolean(deliveryFromQuery?.deliveryCampgroundId)) {
      if (listingData?.instant_book_leeway) {
        const from = dayjs();
        const to = dayjs().add(listingData.instant_book_leeway, 'days');

        datesClone.push({ from, to });
      }
    }

    if (!checkoutBookingDates.from && !bookingDates?.from) {
      return datesClone.map(mappedFn);
    }

    // remove the existing booking/quote date from the unavailable dates
    return datesClone
      .filter(date => {
        return date.from !== bookingDates?.from && date.from !== checkoutBookingDates.from;
      })
      .map(mappedFn);
  },
);

export const getIsUnavailable = createSelector<
  TRootState,
  ReturnType<typeof getUnavailableDates>,
  ReturnType<typeof getFromAndTo>,
  boolean
>(getUnavailableDates, getFromAndTo, (unavailableDates, selectedDates) => {
  const { from, to } = selectedDates;

  if (!to || !from) {
    return false;
  }

  const dayFrom = dayjs(from);
  const dayTo = dayjs(to);
  const isOver12Months = dayjs().add(12, 'month').isBefore(dayTo);

  if (
    dayFrom.isBefore(dayjs(), 'day') ||
    dayFrom.isAfter(dayTo) ||
    !dayFrom.isValid() ||
    !dayTo.isValid() ||
    isOver12Months
  ) {
    return true;
  }

  return unavailableDates.some(unavailableInterval => {
    return areIntervalsOverlapping(
      { start: unavailableInterval.from, end: unavailableInterval.to },
      { start: dayFrom.toDate(), end: dayTo.toDate() },
      { inclusive: true },
    );
  });
});
